import NoSsr from '@material-ui/core/NoSsr'
import { StoryblokComponent } from '@sb-konzept/gatsby-source-storyblok'
import FullScreenLoader from 'components/FullScreenLoader'
import PageContext from 'components/PageContext'
import PageLayout from 'components/PageLayout'
import { graphql } from 'gatsby'
import * as R from 'ramda'
import React, { Suspense, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'

import jumpTo from '../utils/jumpTo'
import {
  createStandortDescription,
  createStandortTitle
} from '../utils/standortDescriptions'

// Custom loader scrolls to anchor
const CustomLoader = () => {
  useEffect(() => () => jumpTo('dantraeger'))
  return <FullScreenLoader />
}

function DojoDantraeger(props) {
  const { data } = props

  const {
    page: {
      content: { body, layout }
    },
    siteInfo // : { content }
  } = data

  const {
    content: { location }
  } = siteInfo

  // console.log("DATA: ", data);

  const { header, footer } = useMemo(
    () =>
      R.applySpec({
        header: R.pathOr(null, [0, 'content', 'header', 0]),
        footer: R.pathOr(null, [0, 'content', 'footer', 0])
      })(layout),
    [layout]
  )

  useEffect(() => jumpTo('dantraeger'), [])

  return (
    <PageContext.Provider value={{ page: data.page }}>
      {header && <StoryblokComponent content={header} />}
      <Helmet>
        <title>{createStandortTitle(location, 'Dantraeger')}</title>
        <meta
          content={createStandortDescription(location, 'Dantraeger')}
          name="description"
        />
      </Helmet>

      <PageLayout>
        <NoSsr>
          <Suspense fallback={<CustomLoader />}>
            {siteInfo.content && (
              <StoryblokComponent content={siteInfo.content} />
            )}
            <StoryblokComponent content={data.page.content} />
          </Suspense>
        </NoSsr>
      </PageLayout>

      {footer && <StoryblokComponent content={footer} />}
    </PageContext.Provider>
  )
}

export default DojoDantraeger

export const pageQuery = graphql`
  query($id: String!, $siteInfo: String!) {
    page: storyblokDojoDantraegerStory(id: { eq: $id }) {
      ...storyblokDojoDantraegerStory
    }
    siteInfo: storyblokDojoSiteInfoStory(full_slug: { eq: $siteInfo }) {
      ...storyblokDojoSiteInfoStory
    }
  }
`
